"use client";
import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";

import Link from "next/link";

export default function HeaderBanner({ slides }) {
  console.log(`SLIDES::::::::::::::`, slides);
  const [currentSlide, setCurrentSlide] = useState(0);
  const intervalRef = useRef(null); // Ref to store the interval ID

  const startSlideShow = (intervalTime) => {
    clearInterval(intervalRef.current); // Clear any existing interval
    intervalRef.current = setInterval(nextSlide, intervalTime); // Set a new interval with the new time
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => {
      return prevSlide >= slides.length - 1 ? 0 : prevSlide + 1;
    });
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    startSlideShow(11000); // When a button is clicked, set interval to 10 seconds
  };

  useEffect(() => {
    startSlideShow(7000); // Start the slideshow with 7 second interval by default

    return () => clearInterval(intervalRef.current); // Cleanup interval on unmount
  }, []);

  return (
    <div className="header-banner">
      <div
        className="image-container"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <Image
              src={slide.image.data.attributes.url}
              width={1920}
              height={1080}
              priority={true}
              alt={
                slide.image.data.attributes.alternayiveText ||
                `Factory Cat Floor Scrubber Banner ${slide.id}`
              }
            />
            <div className={`content-wrapper ${slide.position}`}>
              <div className="content">
                <h2>{slide.title}</h2>
                <p>{slide.description}</p>
                <div className="button-wrapper">
                  {slide.button_one !== null && (
                    <Link
                      className="cta-btn-1"
                      href={slide.button_one.location}
                      key={index}
                    >
                      {slide.button_one.text}
                    </Link>
                  )}
                  {slide.button_two !== null && (
                    <Link
                      className="cta-btn-1"
                      href={slide.button_two.location}
                      key={index + 1}
                    >
                      {slide.button_two.text}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="button-container">
        {slides.slice(0, 5).map((slide, index) => (
          <button
            key={index}
            className={currentSlide === index ? "active" : ""}
            onClick={() => goToSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
}
