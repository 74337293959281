"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";

export default function ScrubberTypes(props) {
  const platforms = props.platforms;
  return (
    <div className="scrubber-types-page">
      <h1>Explore Our Range of Floor Scrubbers and Floor Sweepers</h1>
      <div className="scrubber-types-wrapper">
        {platforms.map((platform) => (
          <Link
            href={`/platform/${platform.machine_style.data.attributes.style}`}
            key={platform.id}
          >
            <div className="scrubber-type">
              <Image
                src={platform.image.data.attributes.url}
                width={500}
                height={500}
                alt={
                  platform.image.data.attributes.alternayiveText ||
                  `Factory Cat Floor Scrubber Banner ${platform.id}`
                }
              />
              <button>Explore</button>
              <h2>{platform.machine_style.data.attributes.style}</h2>
              {/* <p>{platform.description}</p> */}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
