"use client";
import React from "react";
import Image from "next/image";
export default function Gallery(props) {
  const images = props.homePageGallery.images.data.slice(0, 6);
  return (
    <div className="gallery-section">
      {images.map((image) => {
        return (
          <div className="gallery-section_image-container" key={image.id}>
            <Image
              src={image.attributes.url}
              alt={`${image.attributes.url}` || `Factory Cat Floor Scrubber`}
              width={1920}
              height={1080}
            />
          </div>
        );
      })}
    </div>
  );
}
